import React, {useEffect, useRef, useState} from 'react';

import { useRouter } from 'next/router';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import BuildIcon from '@mui/icons-material/Build';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlashOnIcon from '@mui/icons-material/FlashOn';

import useSWR from 'swr';

import cars from '../constants/cars';

import { useTheme } from '@mui/material/styles';


const SearchEstimates = ({open, handleClose}) => {
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');

  const [service, setService] = useState('');

  const [loading, setLoading] = useState(false);

  const modelInputRef = useRef();
  const serviceInputRef = useRef();
  
  const router = useRouter();
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const maxWidth = useMediaQuery(theme.breakpoints.up('md')) ? "sm" : false;

  const { data: models, error } = useSWR(make && `/api/v2/car-model-estimates/?make=${make}`);

  const { data: services } = useSWR(
    make && model && `/api/v2/car-model-job-estimates/?make=${make}&model=${model}`
  );

  useEffect(() => {
    const handleChange = () => {
      handleClose();
      setLoading(false);
    }

    router.events.on('routeChangeComplete', handleChange)
    router.events.on('routeChangeError', handleChange)

    return () => {
      router.events.off('routeChangeComplete', handleChange)
      router.events.off('routeChangeError', handleChange)
    }
  }, [handleClose, router])

  useEffect(() => {
    if (make) {
      modelInputRef.current.focus();
    }
  }, [make])

  useEffect(() => {
    if (make && model) {
      serviceInputRef.current.focus();
    }
  }, [make, model])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Search for Cost Estimates"
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
    >
      <Backdrop
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        p={4}
        display="flex"
        alignItems="center"
        bgcolor="primary.light"
        fontSize="h4.fontSize"
      >
        <Box color="white" mr={2}>Get a <Box sx={{ color: "secondary.main", fontWeight: 500 }} component="span">Quick</Box> Estimate</Box>
        <Box color={"secondary.main"}><FlashOnIcon /></Box> 
      </Box>

      <DialogContent sx={{ mb: 2 }}>
        <Autocomplete
          options={cars}
          value={make}
          autoHighlight={true}
          openOnFocus={true}
          renderInput={(params) => (
            <TextField
              {...params}
              name="car"
              required
              label="Car Make"
              placeholder="Select the car make"
              variant="outlined"
              fullWidth
              autoFocus
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <IconButton edge="start" size="large">
                        <DirectionsCarIcon />
                      </IconButton>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, value) => {
            setMake(value);
            if (model) {
              setModel('');
              setService('');
            }
          }}
          sx={{ mt: 2 }}
          ListboxProps={{
            elevation: 16
          }}
        />

        {error?.response?.status === 404 &&
          <Typography
            color="error"
            sx={{ mt: 2 }}
          >Sorry we don&apos;t have any estimates for this type of car yet</Typography>
        }

        {make &&
        <Autocomplete
          options={models || []}
          value={model}
          loading={make && !models && !error}
          autoHighlight={true}
          openOnFocus={true}
          noOptionsText="No estimates were found"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              name="model"
              required
              label="Car Model"
              placeholder="Select the model"
              variant="outlined"
              fullWidth
              inputRef={modelInputRef}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <IconButton edge="start" size="large">
                        <AirportShuttleIcon />
                      </IconButton>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, value) => {
            setModel(value ? value.value : '');
            setService('');
          }}
          renderOption={(props, option) => {    
            return (
              <li {...props}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography>
                    {option.value} <br />
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
                    {option.quotes} {option.quotes == 1 ? "quote" : "quotes"}
                  </Typography>
                </Box>
              </li>
            );
          }}
          sx={{ mt: 4 }}
        />}

        {model &&
        <Autocomplete
          options={services || []}
          value={service}
          loading={model && !services}
          autoHighlight={true}
          openOnFocus={true}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              name="service"
              required
              label="Service"
              placeholder="Select the service"
              variant="outlined"
              fullWidth
              inputRef={serviceInputRef}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <IconButton edge="start" size="large">
                        <BuildIcon />
                      </IconButton>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, value) => {
            if (value && value.value) {
              setService(value.value);

              setLoading(true);

              router.push({
                pathname: '/cost-estimates/[car]/[model]/[service]/',
                query: {
                  car: make,
                  model,
                  service: value.value
                }
              })

            } else {
              setService('');
            }
          }}
          renderOption={(props, option) => {    
            return (
              <li {...props}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography>
                    {option.value}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option.quotes} {option.quotes == 1 ? "quote" : "quotes"}
                  </Typography>
                </Box>
              </li>
            );
          }}
          sx={{ mt: 4 }}
        />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SearchEstimates;
